<template>
  <div></div>
</template>
<script>
import {getPageStyle} from "../../api/api";
import {getReportPara} from "../../api/threeYears";

export default {
  name: "Detect",
  mounted() {
    this.detect();
  },
  methods: {
    async detect() {
      //来自管理后台
      // if (this.$route.query.fromAdmin) {
      //   sessionStorage.setItem("fromAdmin", 1)
      // }
      //
      // if (isMobile()) {
      //   sessionStorage.setItem("device", 'mobile')
      // } else {
      //   sessionStorage.setItem("device", 'pc')
      // }


      const pageStyleRes = await getPageStyle({programId: this.$route.params.uuid})
      const reportParaRes = await getReportPara({programId: this.$route.params.uuid})
      if (pageStyleRes.code == 200) {
        if (pageStyleRes.data == '可视化+详情') {
          this.$router.push({
            path: './result-head',
            params: {
              uuid: this.$route.params.uuid,
              nsrsbh: reportParaRes.data.nsrsbh,
              action: this.$route.params.action,
            },
            query:{
              desensitization: this.$route.query.desensitization,
            }
          })
        } else if (pageStyleRes.data == '三年版') {
          this.$router.push({
            path: './ThreeYearResult',
            params: {
              uuid: this.$route.params.uuid,
              nsrsbh: reportParaRes.data.nsrsbh,
              action: this.$route.params.action,
            },
            query: {
              bankId: reportParaRes.data.bankId,
              zq: reportParaRes.data.zqId,
              zqLocation: reportParaRes.data.zqId,
              uuid: this.$route.params.uuid,
              nsrsbh: reportParaRes.data.nsrsbh,
              taxNo: reportParaRes.data.invoiceNsrsbh,
              //name: scope.row.bankMc,
              version: reportParaRes.data.tag,
              companyName: reportParaRes.data.companyName,
              desensitization: this.$route.query.desensitization,
              ...this.$route.query,
            },
          })

        } else if (pageStyleRes.data == '体验版') {
          this.$router.push({
            path: './ResultEditor',
            params: {
              uuid: this.$route.params.uuid,
              nsrsbh: reportParaRes.data.nsrsbh,
              action: this.$route.params.action,
            },
            query:{
              desensitization: this.$route.query.desensitization,
            }
          })

        } else if (pageStyleRes.data == '房地产版') {
          this.$router.push({
            path: './ResultEditor',
            params: {
              uuid: this.$route.params.uuid,
              nsrsbh: reportParaRes.data.nsrsbh,
              action: this.$route.params.action,
            },
            query:{
              desensitization: this.$route.query.desensitization,
            }
          })

        } else if (pageStyleRes.data == '详情') {
          this.$router.push({
            path: './ResultEditor',
            params: {
              uuid: this.$route.params.uuid,
              nsrsbh: reportParaRes.data.nsrsbh,
              action: this.$route.params.action,
            },
            query:{
              desensitization: this.$route.query.desensitization,
            }
          })
        } else {
          this.$alert(`报告样式,找不到对应的报告样式:${pageStyleRes.data}`)
        }
      }
    }
  }
}
</script>

<style lang="stylus" scoped>

</style>
